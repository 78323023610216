import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as SearchActions from '@core-app/state/search/search.actions';
import { SearchState } from '@core-app/state/search/search.reducer';
import {
  searchLoading,
  selectSavedSerialNumbers,
  selectSerialSearchResultsBySerialNumber,
} from '@core-app/state/search/search.selectors';
import { ModalKey } from '@frontend-workspace/shared/src/lib/types/modal-key';
import { SerialSearchResult } from '@interfaces';
import { Store } from '@ngrx/store';
import QrScanner from 'qr-scanner';
import { tap } from 'rxjs';

@Component({
  selector: 'fip-part-search-modal',
  templateUrl: './part-search.modal.html',
  styleUrls: ['./part-search.modal.scss'],
})
export class PartSearchModalComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('videoContainer') videoElement: ElementRef<HTMLVideoElement>;

  readonly modalKey: ModalKey = 'part-search_part-search';
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _store = inject(Store<SearchState>);
  private readonly _router = inject(Router);
  private cdr = inject(ChangeDetectorRef);

  savedSerialNumbers$ = this._store.select(selectSavedSerialNumbers);
  searchLoading$ = this._store.select(searchLoading(this.modalKey));
  searchResult?: SerialSearchResult;
  searchFormGroup = new FormGroup({
    partNumber: new FormControl('', [Validators.required]),
  });
  isSearchTriggered = false;
  qrScanner: QrScanner | null = null;
  isScanningActive = false;

  ngOnInit() {
    // Disable form group while loading...
    this.searchLoading$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((loading) => {
        if (loading) {
          this.searchFormGroup.disable();
        } else {
          this.searchFormGroup.enable();
        }
      });
  }

  ngAfterViewInit() {
    // To enforce the use of the new api with detailed scan results, call the constructor with an options object, see below.
    this.qrScanner = new QrScanner(
      this.videoElement.nativeElement,
      (result) => {
        const serialNumber = result.data;

        if (serialNumber) {
          this.submit(serialNumber);
        }
        this.stopScanning();
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
      },
    );
  }

  startScanning() {
    this.qrScanner?.start();
    this.isScanningActive = true;
  }

  stopScanning() {
    this.qrScanner?.stop();
    this.isScanningActive = false;
    this.cdr.detectChanges();
  }

  submit(serialNumber?: string) {
    if (serialNumber) {
      this.searchFormGroup.get('partNumber')?.setValue(serialNumber);
    }

    const param = serialNumber ?? this.searchFormGroup.value.partNumber;
    if (!param || !this.searchFormGroup.valid) {
      return;
    }

    const partNumber = this.searchFormGroup.value.partNumber;

    if (partNumber) {
      this.isSearchTriggered = true;
      // Listen to search result from ngrx store
      this._store
        .select(selectSerialSearchResultsBySerialNumber(partNumber))
        .pipe(
          takeUntilDestroyed(this._destroyRef),
          tap((data) => {
            if (data) {
              this._store.dispatch(
                SearchActions.reorderSearchResults({
                  searchType: 'serialNumbers',
                  payload: partNumber,
                }),
              );
            } else {
              this.searchResult = undefined;
            }
          }),
        )
        .subscribe((data) => (this.searchResult = data));

      this._store.dispatch(
        SearchActions.loadPartBySerialNumber({
          serialNumber: param,
          save: true,
        }),
      );
    }
  }

  navigateToSummary() {
    this._router.navigate(['parts', this.searchResult?.singleItemId]);
  }

  ngOnDestroy() {
    // Clear search retsult before closing modal
    this.searchResult = undefined;
    // Remove loading before closing modal
    this._store.dispatch(
      SearchActions.toggleSearchLoading({
        searchType: this.modalKey,
        loading: false,
      }),
    );

    //  Clean up the QR scanner
    this.qrScanner?.destroy();
    this.qrScanner = null;
  }
}
